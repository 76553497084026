import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import { MdxArticle } from '../types/Article';
import Layout, { Wrapper } from '../layouts/Layout';
import { Paper, Typography } from '@material-ui/core';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { HidableBranding as Branding } from '../components/Branding';
import { ENABLE_MEMBERSHIP } from '../constants';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { CommunityFooter } from '../components/CommunityFooter';

type PageQueryData = {
  allMdx: {
    edges: {
      node: MdxArticle;
    }[];
  };
};

const InterviewJob = styled('p')`
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 14px;
  color: #767f89;
  font-style: italic;
`;

const Interviewee = styled('p')`
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
`;

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;

  & > div {
    margin-bottom: 12px;
  }
`;

const COLOR = '#69c0ff';

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${COLOR};
    font-size: ${theme.typography.h5.fontSize};
    font-weight: 700;

    @media (max-width: 800px) {
      font-size: ${theme.typography.h5.fontSize};
    }
  `};
`;

const PostContainer = styled('div')`
  ${({ theme }) => css`
    max-width: 900px;
    padding: ${theme.spacing(2)}px;
    margin: ${theme.spacing(4)}px auto;

    @media (max-width: 800px) {
      padding: ${theme.spacing(1)}px;
      margin: ${theme.spacing(2)}px auto;
    }
  `};
`;

const PaperInner = styled('div')`
  text-align: left;
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(4)}px;
`;

const InterviewWrapper = styled(Paper)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 100%;
  overflow: hidden;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const InterviewPhoto = styled(Link)`
  height: 100%;
  width: 100%;
  background-position: center center;
  background-size: cover;
  opacity: 0.8;
  display: inline-block;
  margin: 0 auto;
  transition: opacity 0.1s linear;

  @media (max-width: 800px) {
    height: 250px;
  }

  &:hover {
    opacity: 1;
    transition: opacity 0.1s linear;
  }
`;

const PostLink = ({ post }: { post: MdxArticle }) => (
  <div style={{ height: '100%', position: 'relative' }}>
    <InterviewWrapper>
      <div>
        <InterviewPhoto
          to={post.fields.slug}
          style={{ backgroundImage: `url(${post.frontmatter.interviewImage})` }}
        />
      </div>
      <PaperInner>
        <Interviewee>{post.frontmatter.interviewName}</Interviewee>
        <InterviewJob>{post.frontmatter.interviewJob}</InterviewJob>
        <h2>
          <StyledLink to={post.fields.slug}>
            {post.frontmatter.title}
          </StyledLink>
        </h2>
      </PaperInner>
    </InterviewWrapper>
  </div>
);

const IndexPage = ({
  data: {
    allMdx: { edges }
  }
}: {
  data: PageQueryData;
}) => {
  const posts = edges.filter((edge) => {
    if (!ENABLE_MEMBERSHIP) {
      return edge.node.frontmatter.tags.indexOf('premium') === -1;
    }
    return true;
  });

  const Interviews = posts
    .filter((edge) => edge.node.frontmatter.tags.indexOf('interview') !== -1)
    .map((edge) => (
      <div key={edge.node.fields.slug}>
        <PostLink post={edge.node} />
      </div>
    ));

  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Interviews with Developer Bloggers"
        siteUrl="https://bloggingfordevs.com"
        description="Get advice and resources for creating a strategy for your developer blog, and create content that reaches thousands without an existing audience."
        pathname="interviews/"
      />
      <Wrapper>
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          <Branding to="/">Blogging for Devs</Branding>
          <Typography variant="h4" component="h1" gutterBottom>
            Interviews
          </Typography>
          <Typography
            variant="h6"
            component="p"
            paragraph
            style={{ opacity: 0.8, maxWidth: '550px', margin: '0 auto' }}
          >
            See how successful devs who blog and create online got their start
            and learn from their journey
          </Typography>
        </div>
      </Wrapper>
      <PostContainer>
        <Grid>{Interviews}</Grid>
      </PostContainer>
      <CommunityFooter />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            summary
            image
            tags
            interviewJob
            interviewName
            interviewImage
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
